body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  
}

.hero {
  width: 100%;
  min-height: 100vh;
  background-color: #282c34;
  display: flex;
}

.hero .container {
  width: 100%;
  max-width: 400px;
  margin: auto;
  padding: 30px;
  display: flex;
  flex-direction: column;
  border: dotted 1px;
  border-radius: 20px;
  background: #181A1B;
  box-shadow: rgba(136, 165, 191, 0.48) 3px 2px 6px 0px, rgba(255, 255, 255, 0.8) -3px -2px 6px 0px;
}

h1 {
  font-weight: 600;
  margin: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  color: #fff;
}

#info {
  justify-content: center;
  display: flex;
  margin-bottom: 2px;
  color: #fff;
}

.hero .container #number {
  background: #181831;
  padding: 10px;
}

.hero .container #number span {
  color: #9cff9c;
  font-weight: 800;
}

.hero .container button {
  align-self: flex-end;
  border: none;
  outline: none;
  background: #0252C9;
  color: #fff;
  padding: 14px;
  width: 100%;
  font-size: 22px;
  cursor: pointer;
  font-family: sans-serif;
  margin-top: 3px;
}

#kid_text {
  color:#de09e6;
}

#span_num { 
  display: flex; 
  justify-content: center 
}